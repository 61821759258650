import React from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { useRoomsStore } from '../../store/rooms/RoomsStore';
import { useLocalization } from '../../l10n/UseLocalization';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { Role } from '@chessclub/grpc_wrapper/src/api/types/IUser';
import {  RoomCard } from './RoomCard';
import { useAuthStore } from '../../store/auth/AuthStore';
import styled from 'styled-components';
import { AddCard } from '../../components/LowLevelComponents/AddCard';
import { useEditingRoomStore } from '../../store/editing-room/EditingRoomStore';

const StyledContainerTiles = styled.div`
  display: grid;
  padding-top: var(--top-gap) !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-content: flex-start;
  gap: 20px;
  
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export function RoomCardsView() {

  const { rooms } = useLocalization();
  const { items } = useRoomsStore();
  const { startEdit } = useEditingRoomStore();
  const { user } = useAuthStore();

  // const toolbar = user.role === Role.TEACHER && <ToolbarButton
  //   icon={KnownIcons.plus}
  //   text={lesson.add}
  //   onClick={() => startEdit()}
  // />;

  return <PageTemplate
    placeholder={items.length < 8 ? "70%,50%": null}
  >
    <ContentContainer title={rooms.rooms} >
      <StyledContainerTiles>
        {(user.role === Role.TEACHER || user.role === Role.ADMIN) && <AddCard onClick={() => startEdit()}/>}
        {items.map((room,i) => <RoomCard key={i} {...room} />)}
      </StyledContainerTiles>
    </ContentContainer>
  </PageTemplate>;
}
