import Dexie from 'dexie';
import { ITopic, UserId } from '@chessclub/grpc_wrapper';
import { MaterialSearchParams } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';

const dexie = new Dexie('chessclub--materials');

dexie.version(1).stores({
  selectedMaterialTopic: 'userId',
  selectedKnowledgeTopic: 'userId',
  searchCriteria: 'userId'
});


const selectedMaterialTopic: Dexie.Table
  // @ts-ignore
  = dexie.selectedMaterialTopic;

export const selectedMaterialTopicDb = {
  async getSelectedMaterialTopic(userId: UserId) : Promise<ITopic> {
    return (await selectedMaterialTopic.get(userId))?.topic;
  },

  async saveSelectedMaterialTopic(userId: UserId, topic: ITopic) {
    await selectedMaterialTopic.put({topic, userId});
  },

  async deleteSelectedMaterialTopic(userId: UserId) {
    await selectedMaterialTopic.delete(userId);
  },
}


const selectedKnowledgeTopic: Dexie.Table
  // @ts-ignore
  = dexie.selectedKnowledgeTopic;

export const selectedKnowledgeTopicDb = {
  async getSelectedKnowledgeTopic(userId: UserId) : Promise<ITopic> {
    return (await selectedKnowledgeTopic.get(userId))?.topic;
  },

  async saveSelectedKnowledgeTopic(userId: UserId, topic: ITopic) {
    await selectedKnowledgeTopic.put({topic, userId});
  },

  async deleteSelectedKnowledgeTopic(userId: UserId) {
    await selectedKnowledgeTopic.delete(userId);
  },
}


const searchCriteria: Dexie.Table
  // @ts-ignore
  = dexie.searchCriteria;

export const searchCriteriaDb = {

  async createMaterial(search: MaterialSearchParams): Promise<void> {
    return searchCriteria.put(search);
  },


  async getSearchCriteria(userId: UserId): Promise<MaterialSearchParams> {
    return searchCriteria
      .where('userId').equals(userId)
      .toArray()[0];
  },



}