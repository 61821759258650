import React from 'react';
import styled from 'styled-components';
import { SvgImage } from '../../components/Icon/SvgImage';

import fast_forward from '../../svg/icons/fast-forward.svg';
import arrow_right from '../../svg/icons/arrow_right.svg';
import { many, max, min } from '@chessclub/web-game-server/src/shared/util/math';

const PaginationBody = styled.div`
  display: flex;
`;

const Btn = styled.div<{active?:boolean}>`
  color: var(${p => p.active ? "--color-accent" : "--color-icon-main"});
  pointer-events: ${p => p.active ? "none" : "unset"};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font: var(--font-normal);
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--color-text-main);
  }
`;

export function Pagination(props: {
  page: number,
  max: number,
  select: (x: number) => void
}) {
  return <PaginationBody>

    <SvgImage
      icon={fast_forward} rotate={180} withHover
      onClick={() => props.select(max(0,props.page - 5))}
      disabled={props.page === 0}
    />

    <SvgImage
      icon={arrow_right} rotate={180} withHover
      onClick={() => props.select( props.page - 1)}
      disabled={props.page === 0}
    />

    {many(10, i => i - 4 + props.page)
      .filter(i => i >= 0 && i <= props.max)
      .map(i => <Btn active={i === props.page} key={i} onClick={() => props.select(i)}>{i+1}</Btn>)}

    <SvgImage
      icon={arrow_right} withHover
      onClick={() => props.select(props.page + 1)}
      disabled={props.page === props.max}
    />
    <SvgImage
      icon={fast_forward} withHover
      onClick={() => props.select(min(props.max, props.page + 5))}
      disabled={props.page === props.max}
    />
  </PaginationBody>;
}