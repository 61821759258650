import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AddCard } from '../../components/LowLevelComponents/AddCard';
import { useEditingTaskStore } from '../../store/editing-task/EditingTaskStore';
import { MaterialCard } from './MaterialCard';
import { IMaterial } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { Pagination } from '../../pages/MaterialsPage/Pagination';
import { useMaterialsStore } from '../../store/materials/MaterialsStore';

const MaterialCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  align-content: flex-start;
  padding-top: var(--top-gap) !important;
  gap: 20px;
  
  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export function MaterialsList(props: {
  materials: IMaterial[];
  factory: () => IMaterial;
  onDelete?: (mat: IMaterial) => void;
  onClick?: (mat: IMaterial) => void;
  accent?: (mat: IMaterial) => boolean;
}) {

  const { startEditTask } = useEditingTaskStore();
  const { page, setPage,  pageCount} = useMaterialsStore();

  return <>
    <Pagination page={page} max={pageCount} select={setPage}/>
    <MaterialCardsContainer>
      {props.factory && <AddCard onClick={() => startEditTask(props.factory())} />}

      {props.materials.map((mat, i) => <MaterialCard
        material={mat}
        key={i}
        onClick={() => props.onClick(mat)}
        onEdit={props.factory ? () => startEditTask(mat) : null}
        onDelete={props.onDelete ? () => props.onDelete(mat) : null}
        accent={props.accent && props.accent(mat)}
      />)}
    </MaterialCardsContainer>

  </>;
}